* {
    margin:     0;
    padding:    0;
    box-sizing: border-box;
}

body {
    font-family: "Roboto", sans-serif;
}

div.container {
    position:  absolute;
    top:       40%;
    left:      50%;
    transform: translate(-50%, -50%);
    width:     600px;
}

img.logo {
    width:         100%;
    height:        auto;
    position:      relative;
    left:          50%;
    transform:     translateX(-50%);
    margin-bottom: 0.3rem;
}

p {
    font-size:   1.1rem;
    line-height: 1.7rem;
    margin-top:  1rem;
    text-align:  center;
}

a {
    text-decoration: none;
    color:           #66008C;
}

@media only screen and (max-width: 650px) {
    div.container {
        width: 80%;
    }
}